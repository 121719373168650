import React, { useState } from 'react';
import axios from 'axios';
import styles from './App.module.css';
// import logo from "./logo.svg";
import List from './List';
import SearchForm from './SearchForm';
import { uniq } from 'lodash';

const API_ENDPOINT =
  'https://hn.algolia.com/api/v1/search?hitsPerPage=20&query=';

function storiesReducer(state, action) {
  switch (action.type) {
    case 'STORIES_FETCH_INIT':
      return { ...state, isLoading: true, isError: false };
    case 'STORIES_FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        data: action.payload,
      };
    case 'STORIES_FETCH_FAILURE':
      return { ...state, isLoading: false, isError: true };
    case 'REMOVE_STORY':
      return {
        ...state,
        data: state.data.filter(
          (story) => action.payload.objectID !== story.objectID
        ),
      };
    case 'EXTEND_STORIES':
      return {
        ...state,
        data: [...state.data, ...action.payload],
      };
    default:
      throw new Error();
  }
}

const useSemiPersistentState = (key, initialState) => {
  const [value, setValue] = React.useState(
    localStorage.getItem(key) || initialState
  );

  React.useEffect(() => {
    localStorage.setItem(key, value);
  }, [value, key]);

  return [value, setValue];
};

const getUrl = (searchTerm) => `${API_ENDPOINT}${searchTerm}`;

const extractSearchTerm = (url) => url.replace(API_ENDPOINT, '');

function App() {
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useSemiPersistentState('search', 'React');
  const [urls, setUrls] = useState([getUrl(searchTerm)]);

  const [stories, dispatchStories] = React.useReducer(storiesReducer, {
    data: [],
    isLoading: false,
    isError: false,
  });
  const getLastSearches = (urls) =>
    uniq(urls)
      .filter((url) => url.replace(API_ENDPOINT, '') !== searchTerm)
      .slice(-5)
      .map((url) => extractSearchTerm(url));

  const lastSearches = getLastSearches(urls);

  const handleSearchSubmit = (event) => {
    handleSearch(searchTerm);
    event.preventDefault();
  };

  const handleLastSearch = (searchTerm) => {
    handleSearch(searchTerm);
    setSearchTerm(searchTerm);
  };

  const handleSearch = (searchTerm) => {
    const url = getUrl(searchTerm);
    setUrls(urls.concat(url));
  };

  const handleSearchInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFetchStories = React.useCallback(async () => {
    dispatchStories({ type: 'STORIES_FETCH_INIT' });

    try {
      const lastUrl = urls[urls.length - 1];
      const result = await axios.get(lastUrl);
      dispatchStories({
        type: 'STORIES_FETCH_SUCCESS',
        payload: result.data.hits,
      });
    } catch {
      dispatchStories({ type: 'STORIES_FETCH_FAILURE' });
    }
  }, [urls]);

  React.useEffect(() => {
    handleFetchStories();
  }, [handleFetchStories]);

  const handleRemoveStory = (item) => {
    dispatchStories({ type: 'REMOVE_STORY', payload: item });
  };

  const handleExtendStories = async () => {
    const result = await axios.get(
      `https://hn.algolia.com/api/v1/search?hitsPerPage=20&query=${extractSearchTerm(
        urls[0]
      )}&page=${page}`
    );
    setPage(page + 1);
    console.log(result.data.hits);
    dispatchStories({ type: 'EXTEND_STORIES', payload: result.data.hits });
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.headlinePrimary}>
        Hacker Search{' '}
        <a href='https://github.com/Drblessing/stylish-hn-search'>
          <img
            src='GitHub-Mark-Light-120px-plus.png'
            width='42'
            height='42'
            alt='github'
          ></img>{' '}
        </a>
      </h1>

      <SearchForm
        searchTerm={searchTerm}
        onSearchInput={handleSearchInput}
        onSearchSubmit={handleSearchSubmit}
        buttonClass='button button_small'
      />

      {lastSearches.map((searchTerm, index) => (
        <>
          <button
            key={searchTerm + index}
            type='button'
            onClick={() => handleLastSearch(searchTerm)}
            className={styles.button}
          >
            {searchTerm}
          </button>
          &nbsp;
        </>
      ))}
      <br></br>
      <br></br>
      {stories.isError && <p> Something went wrong ... </p>}

      {stories.isLoading ? (
        <p> Loading ... </p>
      ) : (
        <List list={stories.data} onRemoveItem={handleRemoveStory} />
      )}
      <button
        type='button'
        onClick={() => {
          handleExtendStories();
        }}
        className={styles.button}
      >
        More Results
      </button>
    </div>
  );
}

// Prod and testing
export default App;
export { storiesReducer };
